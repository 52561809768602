import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
    API_BYPASS_ERROR_HANDLER_HEADER_NAME,
    FriendlyProblemDetails,
    Severity,
    UserErrorType,
} from '@wdx/shared/utils';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';

const APP_HANDLED_ERROR_CODES = [409];

const BYPASS_URL_REGEXS = [/envelope\/.*\/coc$/i];

interface ErrorEvent {
    status?: number;
    message?: string;
    detail?: string;
    traceId?: string;
    error?: Error & FriendlyProblemDetails;
}

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    constructor(private toastService: WdxToastService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: ErrorEvent) => {
                if (
                    !this.bypassForDownStreamErrors(error) &&
                    !this.bypassErrorHandler(req, error) &&
                    !APP_HANDLED_ERROR_CODES.includes(Number(error?.status)) &&
                    BYPASS_URL_REGEXS.every((regex) => !regex.test(req.url))
                ) {
                    this.toastService.show({
                        header: `An Error Has Occurred ${
                            error?.status ? `(Code: ${error?.status})` : ''
                        }`,
                        body: [
                            `Details: ${
                                error.message || error.detail || 'None'
                            }`,
                            `Trace ID: ${error.traceId || 'None'}`,
                        ],
                        severity: Severity.Danger,
                        closeOthers: true,
                    });
                }
                throw error;
            }),
        );
    }

    private bypassErrorHandler(
        req: HttpRequest<any>,
        error: ErrorEvent,
    ): boolean {
        const bypassHeader = req.headers.get(
            API_BYPASS_ERROR_HANDLER_HEADER_NAME,
        );
        if (bypassHeader === null) {
            return false;
        }
        const bypassRegex = new RegExp(bypassHeader);
        return bypassRegex.test(error.status?.toString() || '');
    }

    private bypassForDownStreamErrors(error: ErrorEvent): boolean {
        return (
            error.status === 422 &&
            error.error?.errorCode === UserErrorType.DownstreamError
        );
    }
}
