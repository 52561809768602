import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { EntityOperations, SystemEntity } from '@wdx/shared/utils';

@Injectable({ providedIn: 'root' })
export class OperationApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getOperations(
        entityType: SystemEntity,
        entityId: string,
    ): Observable<EntityOperations> {
        return this.get<EntityOperations>(
            `${entityType?.toLowerCase()}/${entityId}/operation`,
        );
    }
}
