import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGetConfig } from '@wdx/shared/utils';
import { Observable } from 'rxjs';

@Injectable()
export class FilesService {
    constructor(private http: HttpClient, private config: IGetConfig) {}

    uploadFile(
        file: File,
        customEndpoint?: string
    ): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append(file.name, file);

        const END_POINT = customEndpoint || 'file';

        return this.http.request(
            new HttpRequest(
                'POST',
                `${this.config.getConfiguration().API_BASE}/${END_POINT}`,
                formData,
                {
                    reportProgress: true,
                }
            )
        );
    }
}
