import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileDownloadService, FilesService } from './+state';
import { StoreModule } from '@ngrx/store';
import * as fileDownload from './+state/file-download/file-download.reducer';
import * as filesStore from './+state/files/files.reducer';
import { FileDownloadEffects } from './+state/file-download/file-download.effects';
import { FilesEffects } from './+state/files/files.effects';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(fileDownload.FEATURE_KEY, fileDownload.reducer),
        StoreModule.forFeature(filesStore.FEATURE_KEY, filesStore.reducer),
        EffectsModule.forFeature([FileDownloadEffects, FilesEffects]),
    ],
    providers: [FileDownloadService, FilesService],
})
export class SharedInfrastructureFileIoModule {}
