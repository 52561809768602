import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FileIndex } from '@wdx/shared/utils';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import * as filesActions from './files.actions';
import { FilesService } from './files.service';

@Injectable()
export class FilesEffects {
    private actions$ = inject(Actions);
    private filesService = inject(FilesService);

    uploadFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(filesActions.uploadFile),
            switchMap((action) =>
                this.filesService
                    .uploadFile(action.file, action.customEndpoint)
                    .pipe(
                        takeUntil(
                            this.actions$.pipe(
                                ofType(filesActions.uploadFileCancel)
                            )
                        ),
                        map((event) => {
                            switch (event.type) {
                                case HttpEventType.Sent: {
                                    return filesActions.uploadFileStarted({
                                        id: action.id,
                                    });
                                }
                                case HttpEventType.UploadProgress: {
                                    return filesActions.uploadFileProgress({
                                        id: action.id,
                                        progress: Math.round(
                                            (100 * event.loaded) /
                                                (event.total as number)
                                        ),
                                    });
                                }
                                case HttpEventType.ResponseHeader:
                                case HttpEventType.DownloadProgress: {
                                    return filesActions.uploadFileDownloading({
                                        id: action.id,
                                    });
                                }
                                case HttpEventType.Response: {
                                    const body = (event as HttpResponse<any>)
                                        .body as FileIndex[];

                                    if (event.status === 200) {
                                        return filesActions.uploadFileComplete({
                                            id: action.id,
                                            fileIndex: body[0],
                                        });
                                    } else {
                                        return filesActions.uploadFileFailure({
                                            id: action.id,
                                            error: event.statusText,
                                        });
                                    }
                                }
                                default: {
                                    return filesActions.noop();
                                }
                            }
                        }),
                        catchError((error) =>
                            of(
                                filesActions.uploadFileFailure({
                                    id: action.id,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );
}
